.rbc-header {
  font-size: 16px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: none !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-header-cell .rbc-today {
  background-color: rgba(0, 107, 255, 0.25) !important;
}

.rbc-time-header-content {
  border: none !important;
}

.rbc-day-bg {
  border: none !important;
}

.rbc-today {
  background-color: #ffffff;
}

.rbc-off-range {
  opacity: 0.35;
}

.rbc-off-range-bg {
  background-color: #ffffff;
}

.rbc-row-segment.rbc-off-range .rbc-event {
  opacity: 0.25 !important;
}

.rbc-row-content {
  padding-top: 16px;
  overflow-y: auto;
}

.rbc-row-segment {
  min-height: 35px;
}

.rbc-button-link {
  font-size: 16px;
  padding: 8px;
}

.rbc-date-cell {
  padding-left: 8px;
  text-align: center;
}

.rbc-current {
  .rbc-button-link {
    background-color: #002f6c;
    color: #ffffff;
    border-radius: 100%;
    padding: 8px;
    font-weight: bolder;
    margin-top: -4px;
  }
}

.rbc-active {
  background: bisque !important;
}

.rbc-month-view {
  border: none !important;
}

.rbc-month-row {
  border: none !important;
}

.rbc-row-bg {
  border: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-time-header {
  border: none !important;
}

.rbc-time-content {
  border: none !important;
}

.rbc-timeslot-group {
  padding-top: 4px;
}

.rbc-time-content > * + * > * {
  border-left: none !important;
}

.rbc-today .rbc-events-container {
  background-color: rgba(0, 107, 255, 0.1);
  margin-right: 0;
}

.rbc-event,
.rbc-background-event {
  z-index: 2;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  cursor: auto;
}

.rbc-event:focus {
  border: none !important;
  outline: none;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100% !important;
}

.rbc-event-content {
  display: flex;
  align-items: center;
  padding-left: 8px !important;
}

.rbc-label {
  display: none;
}

.rbc-selected:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 14px;
}

/*----------------------------
scrollbar------------------
-----------------------------*/
* {
  scrollbar-width: thin;
  scrollbar-color: grey white;
}
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track {
}
*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
}

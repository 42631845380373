.cls-1 {
  isolation: isolate;
}

.cls-2 {
  opacity: 0.4;
  mix-blend-mode: multiply;
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  fill: none;
  stroke: #606060;
  stroke-miterlimit: 10;
}

.cls-5 {
  fill: #606060;
}

.cls-6 {
  fill: #f6e9ca;
}

#SvgHoverGroup {
  cursor: pointer;
}
